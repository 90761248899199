import React, { useEffect } from "react"
import ReactGA from "react-ga"
import SEO from "../../components/seo"
import { Link } from "gatsby"
import track from "../../components/track"
import valinorLogo from "../../images/valinor-logo.png"
import "../../components/layout.css"

const SavingsTerms = () => {

  const pageTitle="Savings";
  const pageDescription = "Save on your MOVANTIK prescription today with the Savings Card and more. Get help treating opioid-induced constipation.";
  const pageName = "Savings Terms";

  // Google analytics
  if (typeof window !== "undefined") {
    var host = window.location.hostname
    var GACode = "UA-155993142-4"
    if (host.includes("localhost") || host.includes("bgbdigital")) {
      GACode = "UA-45051197-16"
    }
    ReactGA.initialize(GACode)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  useEffect(() => {

    // add google tag manager script
    const gtmScript = document.createElement('script');
    gtmScript.src = "https://www.googletagmanager.com/gtag/js?id=DC-10089119";
    document.body.appendChild(gtmScript);

    // add global floodlight tags script
    const floodlightScript = document.createElement('script');
    floodlightScript.src = "/floodlight-global.js";
    document.body.appendChild(floodlightScript);

    // pulse tracking
    if (typeof window !== "undefined") {
      var urlHost = window.location.hostname
      var pulseIdScriptContent, pulseNoscriptContent;
      const pulseIdScript = document.createElement('script');
      pulseIdScript.type = 'text/javascript';
      const pulseScript = document.createElement('script');
      pulseScript.type = 'text/javascript';
      const pulseNoscriptNode = document.createElement('noscript');
      if (urlHost.includes('staging')){
        pulseIdScriptContent = 'var pulseSiteID = 24';
        pulseScript.src = '//tracking-beta.explorepulse.com/track.js';
        pulseNoscriptContent = '<p><img src="//tracking-beta.explorepulse.com/track-noscript.php?idsite=24&rec=1" style="border:0;" alt="" /></p>'
      } else {
        pulseIdScriptContent = 'var pulseSiteID = 29';
        pulseScript.src = '//tracking.explorepulse.com/track.js';
        pulseNoscriptContent = '<p><img src="//tracking.explorepulse.com/track-noscript.php?idsite=29&rec=1" style="border:0;" alt="" /></p>';
      }
      pulseIdScript.innerHTML = pulseIdScriptContent;
      document.head.appendChild(pulseIdScript);
      document.head.appendChild(pulseScript);
      pulseNoscriptNode.innerHTML = pulseNoscriptContent;
      document.body.appendChild(pulseNoscriptNode);
    }

    // track when a page has been open for 30+ seconds
    let pageLoadTimeout = setTimeout(function(){
      track('patient ' + pageName + ' page', 'Time Spent', 'DTC Global - Time Spent 30s+');
      track('Global', 'Time Spent', 'DTC Global - Time Spent 30s+ v2');
    },30000);

    return () => {
      clearTimeout(pageLoadTimeout);
    }
  }, [])

  return (
    <>
      <SEO title={pageTitle} description={pageDescription} />
      <div className="savings-print">
        <div className="return">
          Return to <Link to="/savings/" id="patient-savingsterms-link-return" onClick={() => track("patient Savings Terms page", "link click", "DTC Savings Terms-Return to Savings")}><span className="orange">[movantik.com]</span></Link>
        </div>
        <div className="center print-header">SMS Mobile Terms</div>

        <div className="container">
        
          <h4>MOVANTIK<sup>&reg;</sup> (naloxegol) Savings Program Mobile Terms&nbsp;&amp;&nbsp;Conditions</h4>

          <p>MOVANTIK Savings Program SMS (text) messages are sent through PSKW, LLC dba ConnectiveRx, a service provider partner operating on behalf of Valinor Pharma, LLC.</p>

          <p>When you opt-in to the service, we will send you an SMS message to confirm your signup. By providing your cell phone number, you represent that you are the authorized user of the wireless device you use to receive the messages and that you are authorized to approve any changes. Valinor Pharma, LLC. reserves the right to alter these terms and conditions or discontinue the messaging at any time and, at its sole discretion, may add or delete a cellular carrier from this program at any time, without notice. Text messages you receive as part of this program are automated and your responses are not read by any person. Consent to receiving SMS messages is not a requirement to participate in the MOVANTIK Savings Program and you may opt out at any time. Please visit Valinor Pharma, LLC’s privacy policy at <a href="/privacy-policy/" target="_blank" id="patient-savingsterms-link-privacy">https://www.movantik.com/privacy-policy/</a> or contact us for additional information. Please visit the full terms and conditions regarding participation eligibility in the MOVANTIK Savings Program.</p>

          <p><span className="bold">Message and data rates may apply.</span> While we do not charge you for the messages, your mobile service provider may charge you for SMS text messages as a part of your contract or service. Please contact your mobile service provider for details.</p>

          <p>Message frequency varies.</p>

          <p>To stop receiving messages, reply STOP at any time.</p>

          <p>For help, reply HELP or call <span className="nowrap">844-4-MOVSAVE</span>.</p>

          <p className="bold">We are able to deliver messages to the following mobile phone carriers:</p>

          <p><span className="bold">Major carriers:</span> AT&amp;T, Verizon Wireless, Sprint, <span className="nowrap">T-Mobile,</span> MetroPCS, U.S. Cellular, Alltel, Boost Mobile, Nextel, and Virgin Mobile.</p>

          <p><span className="bold">Minor carriers:</span> Alaska Communications Systems (ACS), Appalachian Wireless (EKN), Bluegrass Cellular, Cellular One of East Central IL (ECIT), Cellular One of Northeast Pennsylvania, Cincinnati Bell Wireless, Cricket, Coral Wireless (Mobi PCS), COX, Cross, Element Mobile (Flat Wireless), Epic Touch (Elkhart Telephone), GCI, Golden State, Hawkeye (Chat Mobility), Hawkeye (NW Missouri), Illinois Valley Cellular, Inland Cellular, iWireless (Iowa Wireless), Keystone Wireless (Immix Wireless/PC Man), Mosaic (Consolidated or CTC Telecom), Nex-Tech Wireless, NTelos, Panhandle Communications, Pioneer, Plateau (Texas RSA 3 Ltd), Revol, RINA, Simmetry (TMP Corporation), Thumb Cellular, Union Wireless, United Wireless, Viaero Wireless, and West Central (WCC or 5 Star Wireless). </p>

          <p>***Carriers are not liable for delayed or undelivered messages.***</p>
    
          <footer>

            <img src={valinorLogo} alt="Valinor Pharma, LLC" className="footer-logo" />

                  
            <span className="footer-text end-matter">MOVANTIK is a registered trademark of the AstraZeneca group of companies.<br/>&copy;©2023 Valinor Pharma, LLC. All&nbsp;rights&nbsp;reserved.</span> 

          </footer>
        </div>
      </div>
    </>
  )
}

export default SavingsTerms
